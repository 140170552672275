import { ENABLE_SHIFT_TIME_RECEPTION } from '@/constants/feature-flags';
import { WEDDING_RESEPSI_TIME } from '@/constants';
import useQueryString from './useQueryString';

export const SHIFT_LIST = [
  {
    value: '1',
    shift: 'Resepsi',
    name: 'Sesi 1 (Resepsi)',
  },
  {
    value: '2',
    shift: 'Pemberkatan-Resepsi',
    name: 'Sesi 2 (Pemberkatan & Resepsi)',
  },
  {
    value: '3',
    shift: 'Pemberkatan-Resepsi-Tea',
    name: 'Sesi 3 (Pemberkatan & Resepsi & Tea Pai)',
  },
];

function generateShift(guestShift) {
  const { getQueryValue } = useQueryString();
  let shift = WEDDING_RESEPSI_TIME;

  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  if (guestShift) {
    shift = SHIFT_LIST.find((s) => s.value === guestShift.toString());
  } else {
    shift = SHIFT_LIST.find((s) => s.value === getQueryValue('shift'));
  }

  return shift ? shift.shift : 'Shift';
}

export default generateShift;